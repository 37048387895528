@import '../../../../scss/theme-bootstrap';

.offers-roundel {
  display: none;
  .offers-roundel {
    &__wrapper {
      position: fixed;
      top: 270px;
      width: 100px;
      @include breakpoint($medium-up) {
        top: 240px;
      }
      @include breakpoint($ipad-pro-portrait-only) {
        top: 280px;
      }
    }
    &__close {
      cursor: pointer;
      position: absolute;
      &.small-close {
        #{$rdirection}: 34px;
        top: -9px;
        @include breakpoint($medium-up) {
          #{$rdirection}: 28px;
          top: 4px;
        }
      }
      &.medium-close {
        #{$rdirection}: 17px;
        top: -26px;
        @include breakpoint($medium-up) {
          #{$rdirection}: 4px;
          top: -12px;
        }
      }
      &.large-close {
        #{$rdirection}: -5px;
        top: -9px;
        @include breakpoint($medium-up) {
          #{$rdirection}: -12px;
          top: 5px;
        }
      }
      .icon {
        height: 16px;
        stroke: $black;
        width: 16px;
        @include breakpoint($medium-up) {
          height: 12px;
          width: 12px;
        }
      }
    }
  }
  .header-offers-banner-item {
    display: inline-block;
    text-align: center;
    .elc-link {
      align-items: center;
      display: flex;
      font-family: $font--malone;
      font-size: unset;
      height: 100%;
      justify-content: center;
      overflow: hidden;
      width: 100%;
      &:hover {
        box-shadow: none;
        text-decoration: underline;
      }
    }
  }
  .offers-roundel-circle-size-small {
    border-radius: 30px;
    height: 60px;
    padding: 0;
    position: absolute;
    width: 60px;
  }
  .offers-roundel-circle-size-medium {
    border-radius: 50px;
    height: 77px;
    padding: 0;
    width: 77px;
    @include breakpoint($medium-up) {
      height: 84px;
      width: 84px;
    }
  }
  .offers-roundel-circle-size-large {
    #{rdirection}: 24px;
    border-radius: 80px;
    height: 100px;
    padding: 0;
    position: absolute;
    width: 100px;
  }
  .offers-roundel-circle-position-top-right {
    #{$rdirection}: 15px;
    @include breakpoint($medium-up) {
      #{$rdirection}: 50px;
    }
  }
  .offers-roundel-circle-position-top-left {
    #{$ldirection}: 15px;
    @include breakpoint($medium-up) {
      #{$ldirection}: 50px;
    }
  }
  .offers-roundel-circle-position-bottom-right {
    #{$rdirection}: 15px;
    bottom: 50px;
    top: 550px;
    @include breakpoint($medium-up) {
      #{$rdirection}: 50px;
      top: 480px;
    }
  }
  .offers-roundel-circle-position-bottom-left {
    #{$ldirection}: 15px;
    bottom: 50px;
    top: 550px;
    @include breakpoint($medium-up) {
      #{$ldirection}: 50px;
      top: 480px;
    }
  }
  &__close {
    .slide_panel_block__reveal & {
      display: none;
    }
  }
}

.front,
.node-type-elc-mpp,
.section-product {
  .offers-roundel-display,
  .offers-roundel__wrapper {
    display: block;
    z-index: 100;
  }
}

.offers-roundel-hidden {
  display: none;
}
